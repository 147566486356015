import React from 'react'
import starIcon from '../../../assets/images/star-icon.png'
import howItWork from '../../../assets/images/how-its-work.png'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Clienții Ne Apreciază
                            </span>
                            <h2>De ce să alegi consultanța noastră?</h2>
                            <p>Colegii noștri sunt profesioniști cu înaltă calificare și experiență, care au cunoștințe în diverse tehnologii și domenii de activitate.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Colaborăm cu cele mai mari companii din industria IT.</h3>
                                    <p>Din colaborările noastre culegem cele mai bune experiențe pe care apoi le implementăm în toate proiectele ulterioare.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Experientă si expertiză</h3>
                                    <p>Avem o echipă de consultanți experimentați și înalt calificați, care au cunoștințe și experiență vastă într-o gamă largă de domenii IT, cum ar fi dezvoltarea strategiei, managementul proiectelor, securitatea cibernetică și transformarea digitală.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Atenție personalizată</h3>
                                    <p>Înțelegem importanța construirii unor relații pe termen lung cu clienții noștri, iar consultanții noștri sunt dedicați să ofere atenție și sprijin personalizat pe parcursul angajamentului.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWork} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs