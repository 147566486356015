import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import AboutUsContent from '../../components/solutii/consultanta/AboutUsContent'
import WhyChooseUs from '../../components/solutii/consultanta/WhyChooseUs'

const AboutUs = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Consultanță IT"
                homePageText="Soluții"
                homePageUrl="/" 
                activePageText="Consultanță IT"
            />
            <AboutUsContent />
            <WhyChooseUs />
            <Footer />
        </Layout>
    );
}

export default AboutUs;